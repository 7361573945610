import axios from "axios";
import { makeAutoObservable } from "mobx";
import jwt_decode from "jwt-decode";
import { store } from "./store";
import { publicIpv4 } from "public-ip";

export default class AuthStore {
  jwtToken: string | null = localStorage.getItem("payroll_jwtToken");

  decodedToken: {
    unique_name: string;
    nameid: string;
    email: string;
    nbf: number;
    exp: number;
    iat: number;
  } = { unique_name: "", nameid: "", email: "", exp: 0, iat: 0, nbf: 0 };

  logginSetting: {
    isShow: boolean;
    isLogin: boolean;
    isGetStarted: boolean;
  } = { isShow: false, isLogin: false, isGetStarted: false };

  isAllowLoginClose: boolean = true;

  isShowLogOutScreen: boolean = false;

  isCurrentUserAdmin: boolean = false;

  currentAdminLevel: number = 0;

  isAdminAccessClient: boolean = false;

  isUserLoggedIn: boolean = false;

  ipv4 = "";

  constructor() {
    makeAutoObservable(this);
  }

  getIp = async () => {
    this.ipv4 = await publicIpv4();
  };

  setIsUserLoggedIn = (temp: boolean) => {
    this.isUserLoggedIn = temp;
  };

  openLogin = () => {
    this.logginSetting = {
      isShow: true,
      isLogin: true,
      isGetStarted: false,
    };
  };

  isDisableClientSideAction = (): boolean => {
    let result = false;

    if (
      this.isCurrentUserAdmin !== true &&
      store.irdUserStore.currentIrdUser === undefined
    ) {
      store.irdUserStore.getCurrentIrdUserFromDB("isDisableClientSideAction");
    }

    if (this.isCurrentUserAdmin) {
      if (store.irdUserStore.currentIrdUser?.isAdminManage === true) {
        // donothing, don't disable
      } else {
        return true;
      }
    }

    return result;
  };

  getRequestHeader = async () => {
    await this.getIp();
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("payroll_jwtToken")}`,
        Ipv4: this.ipv4,
      },
    };

    console.log(config);

    return config;
  };

  openGetStarted = () => {
    this.logginSetting = {
      isShow: true,
      isLogin: false,
      isGetStarted: true,
    };
  };

  closeLogin = () => {
    this.logginSetting = {
      isShow: false,
      isLogin: false,
      isGetStarted: false,
    };
  };

  openAuthLogout = (fromWhere: string) => {
    console.log(fromWhere);
    this.isShowLogOutScreen = true;
  };

  closeAuthLogout = () => {
    this.isShowLogOutScreen = false;
  };

  setJwtToken = (tempToken: string) => {
    this.jwtToken = tempToken;
    localStorage.setItem("payroll_jwtToken", tempToken);
  };

  clearJwtToken = () => {
    localStorage.setItem("payroll_jwtToken", "");
    this.setCurrentIrduserId("");
    store.irdUserStore.setAppUser(undefined);
  };

  getCurrentIrduserId = (): string | null => {
    return localStorage.getItem("currentIrdUserId");
  };
  setCurrentIrduserId = (currentIrdUserId: string) => {
    localStorage.setItem("currentIrdUserId", currentIrdUserId);
  };

  setEmailUsed = (tempEmail: string) => {
    localStorage.setItem("emailUsed", tempEmail);
  };

  isTokenValid = async (
    isAdmin: boolean,
    isIgnoreAuthLogout?: boolean
  ): Promise<boolean> => {
    const config = await this.getRequestHeader();

    const bodyParameters = {
      IsAdmin: isAdmin,
    };

    let tempResult = false;

    await axios
      .post("/BeforeLogin/IsTokenValid", bodyParameters, config)
      .then((response) => {
        if (response.data === true) {
          tempResult = true;
        } else {
          //not admin, then show login
          if (isIgnoreAuthLogout === true) {
            // ignore authLogout
          } else {
            this.openAuthLogout("logout 1");
          }
          console.log("IsTokenValid - 122");
        }
      })
      .catch((error) => {
        // eslint-disable-next-line no-lone-blocks
        {
          //not admin, then show login
          console.log(error);

          if (isIgnoreAuthLogout === true) {
            // ignore authLogout
          } else {
            this.openAuthLogout("logout 2");
          }

          console.log("IsTokenValid - 133");
        }
      });

    return tempResult;
  };

  checkIsThisAdmin = async () => {
    // const config = {
    //   headers: { Authorization: `Bearer ${this.jwtToken}` },
    // };
    const bodyParameters = {};

    console.log(this.jwtToken);

    let requestHeader = await this.getRequestHeader();

    await axios
      .post("/BeforeLogin/IsAdmin", bodyParameters, requestHeader)
      .then((response) => {
        console.log(response.data);

        if (response.data === true) {
          this.isCurrentUserAdmin = true;

          axios
            .post("/Admin/GetCurrentAdminLevel", bodyParameters, requestHeader)
            .then((response) => {
              console.log(response.data);

              this.currentAdminLevel = response.data;
            })
            .catch((error) => {
              // eslint-disable-next-line no-lone-blocks
              {
                //not admin, then show login
                console.log(error);

                this.isCurrentUserAdmin = false;
              }
            });
        } else {
          //not admin, then show login
          this.isCurrentUserAdmin = false;
        }
      })
      .catch((error) => {
        // eslint-disable-next-line no-lone-blocks
        {
          //not admin, then show login
          console.log(error);

          this.isCurrentUserAdmin = false;
        }
      });
  };

  setIsAdminAccessClient = (state: boolean) => {
    this.isAdminAccessClient = state;
  };

  setDecodedToken = (thisToken: string) => {
    this.decodedToken = jwt_decode(thisToken);
  };

  getFrontEndAppVersion = async (): Promise<{
    appVersion: string;
    appVersionDateTime: string;
  }> => {
    const result: { appVersion: string; appVersionDateTime: string } = {
      appVersion: "",
      appVersionDateTime: "",
    };

    await axios
      .get("/Admin/FrontEndAppVersion_Get")
      .then((response) => {
        const serverResponse = response.data;

        result.appVersion = serverResponse.item1;
        result.appVersionDateTime = serverResponse.item2;
      })
      .catch((error) => {
        // eslint-disable-next-line no-lone-blocks
        {
          //not admin, then show login
          console.log(error);
        }
      });

    return result;
  };

  getAppVersionFromLocalStorage = (): string | null => {
    return localStorage.getItem("payroll_appVersion");
  };
  setAppVersionToLocalStorage = (temp: string) => {
    localStorage.setItem("payroll_appVersion", temp);
  };

  checkIfNeedToRefresh = async () => {
    const localAppVersion = this.getAppVersionFromLocalStorage();

    let serverAppVersion = "";

    const tempResult = await this.getFrontEndAppVersion();

    serverAppVersion = tempResult.appVersion;

    console.log(localAppVersion);
    console.log(serverAppVersion);

    if (localAppVersion === null || localAppVersion === "") {
      this.setAppVersionToLocalStorage("10000");
    }

    if (
      serverAppVersion !== "" &&
      serverAppVersion.toString() !== localAppVersion?.toString()
    ) {
      this.setAppVersionToLocalStorage(serverAppVersion);

      window.location.reload();
    }
  };

  checkLoggedUser = async (location: any) => {
    await this.checkIsThisAdmin();

    this.setIsAdminAccessClient(false);

    if (this.jwtToken !== null && this.jwtToken !== "") {
      this.setDecodedToken(this.jwtToken);
    }

    if (
      location.pathname.toLowerCase().includes("dashboard") === true &&
      location.pathname.toLowerCase().includes("admin") === false
    ) {
      if (this.jwtToken === null || this.jwtToken === "") {
        //No user, then show login
        this.openAuthLogout("logout 3");

        console.log("checkLoggedUser - 161");
      } else {
        await this.isTokenValid(false);
      }

      if (this.isCurrentUserAdmin !== false) {
        //check if admin
        //use A try to access B, then show login

        var checkIfAdmin = await this.isTokenValid(true);

        if (checkIfAdmin === true) {
          this.isAdminAccessClient = true;
        } else {
          //
          this.openAuthLogout("logout 4");

          console.log("checkLoggedUser - 180");
        }
      }
    }
    if (location.pathname.toLowerCase().includes("admin")) {
      if (this.jwtToken === null || this.jwtToken === "") {
        //No user, then show login
        this.openAuthLogout("logout 5");

        console.log("checkLoggedUser - 193");
      } else {
        await this.isTokenValid(true);
      }
    }
  };
}
